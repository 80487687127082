import axios from 'axios'

export function enviarEmail(payload){
    console.log(payload)
    return async function(dispatch){
        const params = new URLSearchParams();
        params.append('name', payload.name)
        params.append('email', payload.email)
        params.append('message', payload.message)
        params.append('telefono', payload.telefono)
        const json = await axios.post(`./php/contact_me.php`, params)
        console.log("Esto me arroja",json)
        return json
    }
}

export function enviarEmailFlotante(payload){
    console.log(payload)
    return async function(dispatch){
        const params = new URLSearchParams();
        params.append('name', payload.name)
        params.append('email', payload.email)
        params.append('telefono', payload.telefono)
        const json = await axios.post(`./php/contact_me_flotante.php`, params)
        console.log("Esto me arroja",json)
        return json
    }
}

