import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.css";

const Services = () => {
  return (
    <section>
      <Row className="services-component">
        <div className="title-section-divider">
          <p className="title-section upperCase-font">SERVICIOS</p>
        </div>
        <Col sm={4} className="sc-columns">
          <p className="bold sc-columns-title">Data<br />Management</p>
          <p className="sc-subtitle">Data Arquitecture</p>
          <p className="sc-subtitle">Data Integration</p>
          <p className="sc-subtitle">Data Quality<br />Management</p>
          <p className="sc-subtitle">Data Storage</p>
          <p className="sc-subtitle">Data Analytics &<br />Reporting</p>
        </Col>
        <Col sm={4} className="sc-columns">
          <p className="bold sc-columns-title">Data Science<br />& Analytics</p>
          <p className="sc-subtitle">Sales & Marketing</p>
          <p className="sc-subtitle">Retail</p>
          <p className="sc-subtitle">Supply Chain</p>
        </Col>
        <Col sm={4} className="sc-columns">
          <p className="bold sc-columns-title">Data<br />Visualization</p>
          <p className="sc-subtitle">Custom Dashboards</p>
        </Col>
      </Row>
    </section>
  );
};

export default Services;
