import React from "react";
import { Col, Row } from "react-bootstrap";
import NuestroModelo from "../assets/img/nuestro_modelo.svg";
import "./index.css";

const OurModel = () => {
  return (
    <section id="modelo" className="ourModel">
      <div className="title-section-divider">
        <p className="title-section upperCase-font">NUESTRO MODELO</p>
      </div>
      <Row className="ourModel-position" style={{ textAlign: "justify" }}>
        <Col sm={6} className="flex">
          <img src={NuestroModelo} alt="nuestro modelo" width="100%" />
        </Col>
        <Col sm={6}>
          <div className="flex ourModel-padding">
            <p className="om-number color-font margin-rigth-xs">1</p>
            <p className="om-description">
              Definición de las preguntas de negocio correctas para que se
              puedan tratar de forma analítica y respondan a los objetivos de la
              empresa.
            </p>
          </div>
          <div className="ourModel-padding flex-center-vertical">
            <p className="om-number color-font margin-rigth-xs">2</p>
            <p className="om-description">
              Recopilación y extracción de datos.
            </p>
          </div>
          <div className="flex ourModel-padding">
            <p className="om-number color-font margin-rigth-xs">3</p>
            <p className="om-description">
              Limpieza y reestructuración de los datos para que sean aptos para
              ser analizados.
            </p>
          </div>
          <div className="flex ourModel-padding">
            <p className="om-number color-font margin-rigth-xs">4</p>
            <p className="om-description">
              <span className="bold">Análisis de los datos:</span> procesos,
              análisis exploratorio, creación y optimización de modelos,
              análisis predictivos, machine learning y estadística.
            </p>
          </div>
          <div className="flex ourModel-padding">
            <p className="om-number color-font margin-rigth-xs">5</p>
            <p className="om-description">
              Presentación de insights y recomendaciones de negocio.
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default OurModel;
