import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Conect from "../ConectForm.js";
import { BsArrowRight } from "react-icons/bs";
import DataLinkLogo from "../assets/img/dsta_lab_link.svg";
import scrollUp from "../assets/img/Scroll_ColorStroke.svg";

import retail from "../assets/img/Retial.png";
import market from "../assets/img/Market-Places.png";
import iscam from "../assets/img/ISCAM.png";
import purePlayer from "../assets/img/Pure-Players.png";
import nielsen from "../assets/img/Nielsen.png";
import erp from "../assets/img/ERP.png";
import ecommerce from "../assets/img/E-Commerce.png";
import cubosInfo from "../assets/img/Cubos.png";

import CustomDashboards from "../assets/img/GIF_WEB DATALABMX-2022_LINK.gif";

import backgroundImage2 from "../assets/img/fondo.svg";
import backgroundImage3 from "../assets/img/fondo2.svg";


const DataLink = () => {
  return (
    <Container id="link">
      <Row className="flex-center-vertical">
        <Col sm={10} lg={9}>
          <div className="title-section-divider">
            <img
              src={DataLinkLogo}
              alt="logo data lab"
              className="titleHeader-img"
            />
          </div>
        </Col>
        <Col sm={2} lg={3}>
          <div className="titleHeader-background titleHeader-contac">
            <Link to="/contacto" className="titleHeader-link">
              Contacto <BsArrowRight />
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="dl-space-title">
        <p className="title-background-orange">
          INTEGRA tu información y transforma tu negocio
        </p>
        <p className="description-section">
          <span className="bold">Data Lab Mx LINK</span> es un servicio que
          ayuda a <span className="bold">integrar tu información</span> desde
          distintas bases de datos, mediante la automatización de la descarga y
          el procesamiento de datos a gran escala.
        </p>

      </Row>
      {/* <div className="titleHeader-background titleHeader-contac-block">
        <Link to="/contacto" className="titleHeader-link">
          Contacto <BsArrowRight />
        </Link>
      </div> */}
        <img className="imageVisualGif" src={CustomDashboards} alt="custom dash gif" width="100%" />
      <section className="container-bar-orange">
        <div className="text-background-orange upperCase-font">
          <p>
            A través de tableros de indicadores y reportes personalizados podrás
            monitorear tus ventas, inventarios, precios, etc. y tomar mejores
            decisiones para tu empresa.
          </p>
        </div>
      </section>
      <div className="bg-svg-2" style={{ backgroundImage: `url(${backgroundImage3})`, }}></div>

      <div className="scroll-image">
        <a href="#link">
          <img src={scrollUp} alt="scroll boton" />
        </a>
      </div>
      <Row className="dl-space-margin_top">
        <p className="dl-subtitle-text bold">
          Procesamos cualquier tipo de base de datos para tu negocio:
        </p>
        <div className="groupLink">
          <div className="groupLinkIndividual">
          <img src={retail} alt="" width="15%" />
          <p>Retail</p>
          </div>

          <div className="groupLinkIndividual">
          <img src={market} alt="" width="15%" />
          <p>Market Places</p>
          </div>
          
          <div className="groupLinkIndividual">
          <img src={iscam} alt="" width="15%" />
          <p>Iscam</p>
          </div>

          <div className="groupLinkIndividual">
          <img src={purePlayer} alt="" width="15%" />
          <p>Pure Players</p>
          </div>

          <div className="groupLinkIndividual">
          <img src={nielsen} alt="" width="15%" />
          <p>Nielsen</p>
          </div>

          <div className="groupLinkIndividual">
          <img src={erp} alt="" width="15%" />
          <p>ERP (SAP, ORACLE, etc.)</p>
          </div>

          <div className="groupLinkIndividual">
          <img src={ecommerce} alt="" width="15%" />
          <p>E-Commerce</p>
          </div>

          <div className="groupLinkIndividual">
          <img src={cubosInfo} alt="" width="15%" />
          <p>Cubos de información (CSV, Excel, SQL, MySQL, etc.)</p>
          </div>
        </div>
      </Row>



      <div className="incrementaModel">
        <div sm={6} className="ourModel">
          <p className="dl-title-lists">
            <span className="color-font bold">Incrementa</span> la Productividad
            Y <span className="color-font bold">mejora</span> la toma de
            decisiones
          </p>
        </div>
        <div sm={6} className="ourModel">
          <div className="flex ourModel-padding">
            <p className="color-font margin-rigth-xs dl-number">1</p>
            <div>
              <p className="color-font upperCase-font dl-number-title">
                Integra
              </p>
              <p className="dl-number-description">
                toda tu información de forma automatizada para el análisis y
                toma de decisiones basadas en datos actualizados y confiables.
              </p>
            </div>
          </div>
          <div className="flex ourModel-padding">
            <p className="color-font margin-rigth-xs dl-number">2</p>
            <div>
              <p className="color-font upperCase-font dl-number-title">
                Optimiza
              </p>
              <p className="dl-number-description">
                el tiempo en la descarga de datos y la generación de reportes y
                evita errores de cálculo por depender del factor humano.
              </p>
            </div>
          </div>
          <div className="flex ourModel-padding">
            <p className="color-font margin-rigth-xs dl-number">3</p>
            <div>
              <p className="color-font upperCase-font dl-number-title">
                Accesa
              </p>
              <p className="dl-number-description">
                a los distintos tableros y reportes, para mejorar el flujo de
                trabajo. Tu equipo podrá tener acceso total a la información que
                requieran analizar.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Conect />
    </Container>
  );
};

export default DataLink;
