import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import OurModel from "../OurModel";
import Services from "../Services";

import DataManagementLogo from "../assets/img/data_management.svg";
import DataArchitecture from "../assets/img/data_architecture.svg";
import DataIntegration from "../assets/img/data_integration.svg";
import DataQuality from "../assets/img/data_quality_management.svg";
import DataStorage from "../assets/img/data_storage.svg";
import DataAnalytics from "../assets/img/data_analytics.svg";
import Conect from "../ConectForm.js";
import scrollUp from "../assets/img/Scroll_ColorStroke.svg";

import backgroundImage2 from "../assets/img/fondo.svg";
import backgroundImage3 from "../assets/img/fondo2.svg";


const DataManagement = () => {
  return (
    <>

    <Container id="management" >
      <section >
        <div style={{zIndex: "99999"}} className="title-section-divider dm-title-margin_bottom">
          <span className="title-section upperCase-font">
            <span className="secondary-color">Data</span>{" "}
            <span className="color-font dm-title-padding_rigth">
              management
            </span>
            <img
              src={DataManagementLogo}
              alt="data management logo"
              className="dm-title_img"
            />
          </span>
        </div>
        <Row className="dm-column_container">
          <Col sm={4}>
            <p className="dm-column_title">Integra</p>
            <p className="dm-column_title bold">tus datos</p>
          </Col>
          <Col sm={8}>
            <p className="dm-column_description">
              Te ayudamos a integrar, transformar, estructurar y limpiar tus
              datos para que sean utilizables para el análisis y puedas alcanzar
              su potencial.
            </p>
          </Col>
        </Row>
        
      <section className="container-bar-orange">
        <div className="text-background-orange upperCase-font">
          <p>
              Los datos de tu empresa pueden ser uno de tus activos más
              valiosos, si los usas correctamente.
          </p>
        </div>
      </section>
      <div className="bg-svg-1" style={{ backgroundImage: `url(${backgroundImage2})`, }}></div>
      <div className="bg-svg-2" style={{ backgroundImage: `url(${backgroundImage3})`, }}></div>

        <div className="scroll-image">
          <a href="#management">
            <img src={scrollUp} alt="scroll boton" />
          </a>
        </div>
        <Row className="dm-marginTop-space">
          <Col sm={4} className="dm-columns-padding">
            <img
              src={DataArchitecture}
              alt="data architecture"
              className="dm-columns-img"
            />
            <p className="dm-columns-title">Data<br />Architecture</p>
            <p className="dm-columns-subtitle">
              Diseñamos la arquitectura de datos para capturar, integrar,
              almacenar, analizar y utilizar los datos.
            </p>
          </Col>
          <Col sm={4} className="dm-columns-padding">
            <img
              src={DataIntegration}
              alt="data integration"
              className="dm-columns-img"
            />
            <p className="dm-columns-title">Data<br />Integration</p>
            <p className="dm-columns-subtitle">
              Consolidamos los datos de distintas fuentes con procesos de
              extracción y transformación de datos.
            </p>
          </Col>
          <Col sm={4} className="dm-columns-padding">
            <img
              src={DataQuality}
              alt="data quality"
              className="dm-columns-img"
            />
            <p className="dm-columns-title">Data Quality<br />Management</p>
            <p className="dm-columns-subtitle">
              Estructuramos y limpiamos tus datos para asegurar la calidad de la
              información.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className="dm-columns-padding">
            <img
              src={DataStorage}
              alt="data storage"
              className="dm-columns-img"
            />
            <p className="dm-columns-title">Data<br />Storage</p>
            <p className="dm-columns-subtitle">
              Implementamos soluciones de almacenamiento para datos de
              diferentes escalas y formatos.
            </p>
          </Col>
          <Col sm={4} className="dm-columns-padding">
            <img
              src={DataAnalytics}
              alt="data analytics"
              className="dm-columns-img"
            />
            <p className="dm-columns-title">Data Analytics<br />& Reporting</p>
            <p className="dm-columns-subtitle">
              Diseñamos la infraestructura para Data Analytics, BI y Reporting.
            </p>
          </Col>
        </Row>
        <OurModel />
        <Services />
        <Conect />
      </section>
    </Container>
    </>

  );
};

export default DataManagement;
