import React from "react";
import { Row, Col } from "react-bootstrap";
import LogoBlanco from "../assets/img/data_lab_blanco.svg";
import mail from "../assets/img/mail.svg";
import address from "../assets/img/ubicacion.svg";
import "./index.css";

const Footer = () => {
  return (
    <footer className="footer">
      <img src={LogoBlanco} alt="logo blanco" className="footer-logo" />
      <Row className="footer-padding">
        <Col xs={12} sm={6} lg={6}>
          <Row className="footer-space">
            <Col xs={2}>
              <img src={mail} alt="mail" className="footer-imagen" />
            </Col>
            <Col xs={10}>
              <p className="footer-title">Contacto</p>
              <p className="footer-data bold">info@datalabmx.com</p>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <img src={address} alt="mail" className="footer-imagen" />
            </Col>
            <Col xs={10}>
              <p className="footer-title">Dirección</p>
              <p className="footer-data bold">
                Torre Moliere; Calle Moliere No 13, Piso 7. Col. Polanco III
                Sección. Miguel Hidalgo, CDMX, 11550
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={6} lg={6} className="footer-privacity">
          <p className="paragraph-marginBottom">Aviso de privacidad</p>
          <p className="paragraph-marginBottom">Términos y condiciones</p>
          <p>Manejo de cookies</p>
        </Col>
      </Row>
      <Row>
        <Col className="footer-date">
          {new Date().getFullYear()} Todos los derechos reservados
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
