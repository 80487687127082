// import React from "react";
// import { Col, Container, Form, Row, Button } from "react-bootstrap";
// import {useDispatch} from 'react-redux'
// import { useNavigate } from "react-router-dom";
// import { enviarEmail } from "../../redux/actions/index";




import React, { useState } from "react";
import { Col, Container,Row } from "react-bootstrap";
import {useDispatch} from 'react-redux'
import { useNavigate } from "react-router-dom";
import { enviarEmail } from "../../redux/actions";
import Modal from "../modal/Modal";
import ModalError from "../modal/ModalError";
import styled from 'styled-components';

const ContactUs = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [input, setInput]=useState({
    name: "",
    email: "",
    message: "",
    telefono: "",
    })
  const [estadoModal1, cambiarEstadoModal1] = useState(false);
  const [estadoModalError1, cambiarEstadoModalError1] = useState(false);

  function handleSubmit(e) {
      e.preventDefault();
      if (
        input.name &&
        input.email &&
        input.telefono &&
        input.message 
      ) {
        dispatch(enviarEmail(input));
        cambiarEstadoModal1(!estadoModal1)
        
        //ahora usamos nuestro hook setInput para limpiar nuestros campos
        setInput({
          name: "",
          email: "",
          message: "",
          telefono: "",

        });

        navigate("/contacto");
      } else {
        cambiarEstadoModalError1(!estadoModalError1)
      }
    }

    function handleChange(e) {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    }

  return (
    <Container>
      <section>
        <div className="title-section-divider">
          <p className="title-section upperCase-font">Contacto</p>
        </div>
        <Row>
          <Col sm={6}>
            <p className="contact-title">
              Conecta con <span className="bold">nosotros</span>
            </p>
          </Col>
          <Col sm={6}>
            <p className="contact-description">
              Si quieres conocer más sobre nuestros servicios, escríbenos.
            </p>
            <form
              style={{display: 'flex', flexDirection: "column"}}
              onSubmit={(e)=>handleSubmit(e)}
            >
                <input
                  onChange={(e) => handleChange(e)} type="text" placeholder="Nombre" name="name" value={input.name}
                  className="contact-input"
                />
                <input
                  onChange={(e) => handleChange(e)} type="text" placeholder="Email" name="email" value={input.email}
                  className="contact-input"
                />
                <input
                  onChange={(e) => handleChange(e)} type="text" placeholder="Teléfono" name="telefono" value={input.telefono}
                  style={{borderBottom: "1px solid black"}}
                  className="contact-input"
                />
                <input
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Mensaje"
                    name="message"
                    value={input.message}
                  className="contact-input-message"
                />
              <div className="flex-center">
                <button
                  onClick={e=>handleSubmit(e)}
                  type="submit"
                  className="contact-button-width"
                >
                  Enviar
                </button>
              </div>
            </form>
          </Col>
        </Row>

        <Modal
				estado={estadoModal1}
				cambiarEstado={cambiarEstadoModal1}
				titulo="Hola!"
				mostrarHeader={true}
				mostrarOverlay={true}
				posicionModal={'center'}
				padding={'20px'}
        >
				<Contenido>
					<h1>Tu mensaje fue enviado con exito!</h1>
					<p>El equipo de DatalabMX se pondra en contacto contigo lo mas pronto posible, saludos!</p>
					<BotonModal onClick={() => cambiarEstadoModal1(!estadoModal1)}>Aceptar</BotonModal>
				</Contenido>
			</Modal>

      </section>

      <ModalError
				estado={estadoModalError1}
				cambiarEstado={cambiarEstadoModalError1}
				titulo="Ups!"
				mostrarHeader={true}
				mostrarOverlay={true}
				posicionModal={'center'}
				padding={'20px'}
        >
				<Contenido>
					<h1>Tuvimos problema para enviar tu mensaje!</h1>
					<p>Porfavor llena correctamente todos los campos requeridos, saludos!</p>
					<BotonModal onClick={() => cambiarEstadoModalError1(!estadoModalError1)}>Volver</BotonModal>
				</Contenido>
			</ModalError>

    </Container>
  );
};

export default ContactUs;

const BotonModal = styled.button`
font-family: Avenir;
display: block;
padding: 10px 30%;
border-radius: 100px;
color: #fff;
border: none;
background: #f4511e;
cursor: pointer;
font-weight: 500;
transition: .3s ease all;
&:hover {
  background: #f4511e;
}
`;

const Contenido = styled.div`
font-family: Avenir Next Bold;
	display: flex;
	flex-direction: column;
	align-items: center;
	
	h1 {
    font-family: Avenir;
		font-size: 2.5vw;
		font-weight: 600;
		margin-bottom: 2vw;
	}
	p {
    font-family: Avenir;
		font-size: 1.5vw;
		margin-bottom: 2vw;
    padding: 0 0% 10%;
	}
	img {
		width: 100%;
		vertical-align: top;
		border-radius: 3px;
	}

	@media (max-width: 600px) {

    h1 {
      font-family: Avenir;
      font-size: 4vw;
      font-weight: 600;
      margin-bottom: 4vw;
    }
    p {
      font-family: Avenir;
      font-size: 4vw;
      margin-bottom: 3vw;
      padding: 0 0% 10%;
    }
	}
`;