import React, { useState } from "react";
import {
  Offcanvas,
  NavDropdown,
  Button,
  Navbar,
  Accordion,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import BurgerMenu from "../../components/assets/img/burger.svg";
import Logo from "../assets/img/data_lab_logo.svg";

import LogoBlanco from "../assets/img/data_lab_blanco.svg";
import CerrarBlanco from "../assets/img/cerrar_blanco.svg";
import { BsXLg } from "react-icons/bs";

import "./index.css";

const SideBar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar
        key="sm"
        bg="light"
        expand="sm"
        className="mb-3 sidebar-padding"
        fixed="top"
      >
        <Navbar.Brand href="/" className="sidebar-logo-width">
          <img src={Logo} alt="logo blanco" width="80%" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-sm`}
          aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
              <Nav.Link href="/">
                <img src={LogoBlanco} alt="logo blanco" width="50%" />
              </Nav.Link>
              <BsXLg />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="navbar-body">
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="/nosotros">Nosotros</Nav.Link>
              <NavDropdown
                title="Servicios "
                id={`offcanvasNavbarDropdown-expand-sm`}
              >
                <NavDropdown.Item href="/data-management">
                  Data Management
                </NavDropdown.Item>
                <NavDropdown.Item href="/data-science">
                  Data Science
                </NavDropdown.Item>
                <NavDropdown.Item href="/data-Visualization">
                  Data Visualization
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/data-link">DATA LAB MX LINK</Nav.Link>
              <Nav.Link href="/contacto">Contacto</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>

      <section className="sidebar">
        <Button variant="light" onClick={handleShow} className="sidebar-burger">
          <img src={BurgerMenu} alt="burger menu" width="100%" />
        </Button>

        <img src={Logo} alt="logo blanco" height="18px" className="lateralLogo" />

        <Offcanvas show={show} onHide={handleClose} className="sidebar-opened">
          <Row>
            <Col sm={2}>
              <Offcanvas.Header closeButton  />
              {/* <Offcanvas.Header>
                <img
                  src={LogoBlanco}
                  alt="logo blanco"
                  className="sidebar-columnLogo"
                />
              </Offcanvas.Header> */}
            </Col>
            <Col sm={10} className="sidebar-medium-padding">
              <Nav.Link href="/">
                <img src={LogoBlanco} alt="logo blanco" width="50%" />
              </Nav.Link>
              <div className="sidebar-container_links-left">
                <Nav.Link href="/nosotros">Nosotros</Nav.Link>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Servicios <span style={{width: "1vw", marginRight: "1.3vw"}}></span></Accordion.Header>
                    <Accordion.Body>
                      <Nav.Link href="/data-management">
                        Data Management
                      </Nav.Link>
                      <Nav.Link href="/data-science">Data Science</Nav.Link>
                      <Nav.Link href="/data-Visualization">
                        Data Visualization
                      </Nav.Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Nav.Link href="/data-link">DATA LAB MX LINK</Nav.Link>
                <Nav.Link href="/contacto">Contacto</Nav.Link>
              </div>
            </Col>
          </Row>
        </Offcanvas>
      </section>
    </>
  );
};

export default SideBar;
