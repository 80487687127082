import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../components/assets/img/data_lab_logo.svg";
import { BsArrowRight } from "react-icons/bs";

const TitleHeader = () => {
  return (
    <section className="titleHeader-padding">
      <Row className="flex-center-vertical">
        <Col sm={10} lg={9}>
          <div className="title-section-divider">
            <img src={Logo} alt="logo data lab" className="titleHeader-img" />
          </div>
        </Col>
        <Col sm={2} lg={2}>
          <div className="titleHeader-background titleHeader-contac">
            <Link to="/contacto" className="titleHeader-link">
              Contacto <BsArrowRight />
            </Link>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default TitleHeader;
