import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import OurModel from "../OurModel";
import Services from "../Services";

import DataScienceLogo from "../assets/img/data_scienceLogo.svg";
import SalesForecasting from "../assets/img/sales_forecasting.svg";
import CampaignPerformance from "../assets/img/campaign_performance.svg";
import CustomerSegmentation from "../assets/img/customer_segmentation.svg";
import CustomerBehavior from "../assets/img/customer_behavior.svg";
import MarketingModeling from "../assets/img/marketing_modeling.svg";
import ProductAvailability from "../assets/img/product_availability.svg";
import OptimizeRetail from "../assets/img/optimize_retail.svg";
import StoreOperations from "../assets/img/store_operations.svg";
import ForecastingSupply from "../assets/img/forecasting.svg";
import PromotionalOptimization from "../assets/img/promotional_optimization.svg";
import ForecastingPlanning from "../assets/img/forecasting_and_planning.svg";
import InventoryManagement from "../assets/img/inventory_management.svg";
import Logistics from "../assets/img/logistics.svg";
import SupplierPerformance from "../assets/img/supplier_performance.svg";
import Conect from "../ConectForm.js";
import scrollUp from "../assets/img/Scroll_ColorStroke.svg";

import backgroundImage2 from "../assets/img/fondo.svg";
import backgroundImage3 from "../assets/img/fondo2.svg";

const DataScience = () => {
  return (
    <>
    <Container id="science">
      <section>
        <div className="title-section-divider dm-title-margin_bottom">
          <span className="title-section upperCase-font">
            <span className="secondary-color">Data</span>{" "}
            <span className="color-font dm-title-padding_rigth">
              science & analytics
            </span>
            <img
              src={DataScienceLogo}
              alt="data management logo"
              className="dm-title_img"
            />
          </span>
        </div>
        <Row className="dm-column_container">
          <Col sm={6}>
            <p className="dsa-column_title">Transforma</p>
            <p className="dsa-column_title bold">y acelera</p>
            <p className="dsa-column_title bold">tu negocio</p>
          </Col>
          <Col sm={6}>
            <p className="dsa-column_description">
              Trabajamos en equipo para comprender los objetivos más importantes
              de tu negocio para poder analizar los datos e identificar insights
              que faciliten implementación de acciones que generen crecimiento y
              un mejor ROI.
            </p>
          </Col>
        </Row>
        <section className="container-bar-orange">
        <div className="text-background-orange upperCase-font">
          <p>
              La integración de datos no es suficiente. El objetivo no es tener
              datos sino utilizarlos de la mejor forma.
          </p>
        </div>
      </section>
      <div className="bg-svg-1" style={{ backgroundImage: `url(${backgroundImage2})`, }}></div>
      <div className="bg-svg-2" style={{ backgroundImage: `url(${backgroundImage3})`, }}></div>

        <div className="scroll-image">
          <a href="#science">
            <img src={scrollUp} alt="scroll boton" className="" />
          </a>
        </div>


        <div className="marginItems"></div>

        <div className="agroupItemsScience">
          <h4 className="ItemsScienceTitle bold">Sales & Marketing</h4>
          <div className="containerItemsScience">
              <div>
                <img src={SalesForecasting} alt="" className="dsa-columns-image" />
                <p className="dsa-columns-text">Sales Forecasting</p>
              </div>
              <div>
                <img src={CampaignPerformance} alt="" className="dsa-columns-image"/>
                <p className="dsa-columns-text">Campaign Performance</p>
              </div>
              <div>
                  <img
                  src={CustomerSegmentation}
                  alt=""
                  className="dsa-columns-image"
                />
                <p className="dsa-columns-text">Customer Segmentation</p>
              </div>
              <div>
                <img src={CustomerBehavior} alt="" className="dsa-columns-image" />
                <p className="dsa-columns-text">Customer Behavior</p>
              </div>
              <div>
                <img src={MarketingModeling} alt="" className="dsa-columns-image" />
                <p className="dsa-columns-text">Marketing Modeling Mix</p>
              </div>
          </div>
        </div>

        <div className="separacion"></div>


        <div className="agroupItemsScience">
          <h4 className="ItemsScienceTitle bold">Retail</h4>
          <div className="containerItemsScience">
              <div>
                  <img
                  src={ProductAvailability}
                  alt=""
                  className="dsa-columns-image"
                />
                <p className="dsa-columns-text">Product Availability</p>
              </div>
              <div>
                <img src={CustomerBehavior} alt="" className="dsa-columns-image" />
                <p className="dsa-columns-text">Customer Behavior</p>
              </div>
              <div>
                <img src={OptimizeRetail} alt="" className="dsa-columns-image" />
                <p className="dsa-columns-text">Optimize Retail Locations</p>
              </div>
              <div>
                <img src={StoreOperations} alt="" className="dsa-columns-image" />
                <p className="dsa-columns-text">Store Operations</p>
              </div>
              <div>
                <img src={ForecastingSupply} alt="" className="dsa-columns-image" />
                <p className="dsa-columns-text">
                  Forecasting & Supply Chain Management
                </p>
              </div>
              <div>
                  <img
                  src={PromotionalOptimization}
                  alt=""
                  className="dsa-columns-image"
                />
                <p className="dsa-columns-text">Promotional Optimization</p>
              </div>
          </div>
        </div>

        <div className="separacion"></div>

        <div className="agroupItemsScience">
          <h4 className="ItemsScienceTitle bold">Supply Chain</h4>
          <div className="containerItemsScience">
              <div>
                <img
                  src={ForecastingPlanning}
                  alt=""
                  className="dsa-columns-image"
                />
                <p className="dsa-columns-text">Forecasting and Planning</p>
              </div>
              <div>
                <img
                  src={InventoryManagement}
                  alt=""
                  className="dsa-columns-image"
                />
                <p className="dsa-columns-text">Inventory Management</p>
              </div>
              <div>
                <img src={Logistics} alt="" className="dsa-columns-image" />
                <p className="dsa-columns-text">Logistics</p>
              </div>
              <div>
                <img
                  src={SupplierPerformance}
                  alt=""
                  className="dsa-columns-image"
                />
                <p className="dsa-columns-text">Supplier Performance</p>
              </div>
          </div>
        </div>

        <div className="marginItemsFinal"></div>

        <OurModel />
        <Services />
        <Conect />
      </section>
    </Container>
    </>
  );
};

export default DataScience;
