import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Conect from "../ConectForm.js";
import OurModel from "../OurModel";
import TitleHeader from "../TitleHeader/index.js";

import MetodologiaEnfocada from "../assets/img/Metodologia_Negocio.svg";
import Insights from "../assets/img/Insights_Accionables.svg";
import Foco from "../assets/img/Foco_ROI.svg";
import MonteXanic from "../assets/img/MonteXanic.svg";
import Loco from "../assets/img/LocoTequila.svg";
import LuzSavinon from "../assets/img/Monte_Pio.svg";
import Rica from "../assets/img/rica.svg";
import Genomma from "../assets/img/Genomma_Lab.svg";
import Ibero from "../assets/img/Ibero.svg";
import Gobierno from "../assets/img/GobCDMX.svg";
import Sacmex from "../assets/img/SACMEX.svg";
import DataManagementLogo from "../assets/img/data_management.svg";
import DataScienceLogo from "../assets/img/data_science.svg";
import DataVisualizationLogo from "../assets/img/data_visualization.svg";
import scrollUp from "../assets/img/Scroll_ColorStroke.svg";
import backgroundImage from "../assets/img/fondo.svg";
import backgroundImage2 from "../assets/img/fondo2.svg";

import { BsArrowRight } from "react-icons/bs";


const Home = () => {
  return (
    <Container id="home">
      <TitleHeader />
      <section className="padding-vertical">
        <p className="title-background-orange">data science & analytics</p>
        <p className="home-description-section home-padding">
          para transformar <span className="bold">datos</span> en{" "}
          <span className="bold">insights</span> que incrementen la eficiencia,
          maximizen la efectividad y generen valor.
        </p>
      </section>
      <div className="titleHeader-background titleHeader-contac-block">
        <Link to="/contacto" className="titleHeader-link">
          Contacto <BsArrowRight />
        </Link>
      </div>
      <section>
        <p className="title-columns">
          <span className="color-font">Insights</span> que
        </p>
        <p className="title-columns home-paddingBottom">hacen la diferencia</p>
        <Row className="home-columns-paddingBottom">
          <Col sm={4} className="home-paddingBottom">
            <img
              src={MetodologiaEnfocada}
              alt="metodología enfocada"
              className="home-columns-images"
            />
            <p className="bold font-s home-columns-two_title">Metodología</p>
            <p className="bold font-s home-columns-two_title_bottom">
              Enfocada al Negocio
            </p>
            <p className="home-columns-description">
              Entendemos que no solo se trata de recopilar y analizar
              información. Nuestra misión es descubrir insights que generen
              valor para el negocio.
            </p>
          </Col>
          <Col sm={4} className="home-paddingBottom">
            <img
              src={Insights}
              alt="insights"
              className="home-columns-images"
            />
            <p className="bold font-s home-columns-title">
              Insights Accionables
            </p>
            <p className="home-columns-description">
              Construimos insights, a partir de tus datos, para tomar las
              mejores decisiones para el negocio.
            </p>
          </Col>
          <Col sm={4} className="home-paddingBottom">
            <img src={Foco} alt="foco & roi" className="home-columns-images" />
            <p className="bold font-s home-columns-two_title">Foco En</p>
            <p className="bold font-s home-columns-two_title_bottom">
              Crecimiento & Roi
            </p>
            <p className="home-columns-description">
              Trabajamos con tu equipo para identificar oportunidades de
              crecimiento u optimización, siempre sustentadas por el análisis de
              datos.
            </p>
          </Col>
        </Row>
      </section>
      <div className="scroll-image">
        <a href="#home">
          <img src={scrollUp} alt="scroll boton" />
        </a>
      </div>
      <section className="container-bar-orange">
        <div className="text-background-orange upperCase-font">
          <p>
            La Ciencia de Datos requiere de capabilities analíticos, pero
            también una fuerte visión de negocio para poder extraer y transmitir
            recomendaciones adaptadas a las necesidades de la empresa.
          </p>
        </div>
      </section>
      <div className="bg-svg-1" style={{ backgroundImage: `url(${backgroundImage})`, }}></div>
      <div className="bg-svg-2" style={{ backgroundImage: `url(${backgroundImage2})`, }}></div>

      <section id="servicios" className="marginTop-space">
        <div className="title-section-divider">
          <p className="title-section upperCase-font">SERVICIOS</p>
        </div>
        <p className="services-title-up">EVALUAMOS,</p>
        <p className="services-title">ASESORAMOS Y OPTIMIZAMOS</p>
        <p className="services-subtitle">EN CADA UNO DE NUESTROS SERVICIOS</p>

        <Row className="services-padding">
          <Col sm={4} className="home-paddingBottom">
            <img src={DataManagementLogo} alt="data" width="16%" />
            <p className="services-columnTitle">Data management</p>
            <p className="services-columnSubtitle">
              Te ayudamos a integrar, estructurar y limpiar tus datos.
            </p>
            <Link to="/data-management" className="services-link">
              Leer más <BsArrowRight />
            </Link>
          </Col>
          <Col sm={4} className="home-paddingBottom">
            <img src={DataScienceLogo} alt="data" width="16%" />
            <p className="services-columnTitle">Data Science & Analytics</p>
            <p className="services-columnSubtitle">
              Trabajamos en equipo contigo, para entender tus objetivos.
            </p>
            <Link to="/data-science" className="services-link">
              Leer más <BsArrowRight />
            </Link>
          </Col>
          <Col sm={4} className="home-paddingBottom">
            <img src={DataVisualizationLogo} alt="data" width="16%" />
            <p className="services-columnTitle">Data Visualization</p>
            <p className="services-columnSubtitle">
              Te ayudamos a visualizar tus datos de la mejor forma.
            </p>
            <Link to="/data-visualization" className="services-link">
              Leer más <BsArrowRight />
            </Link>
          </Col>
        </Row>
      </section>
      <OurModel />
      <section id="clientes" className="clients">
        <div className="title-section-divider">
          <p className="title-section upperCase-font">CLIENTES</p>
        </div>
        <div className="mt-5 mb-5">
          <Row className="clients-brands-margin">
            <Col xs={6} sm={3} className="clients-wrapper">
              <img src={MonteXanic} alt="Monte Xanic" className="clients-image" />
            </Col>
            <Col xs={6} sm={3} className="clients-wrapper">
              <img src={Loco} alt="Loco tequila" className="clients-image" />
            </Col>
            <Col xs={6} sm={3} className="clients-wrapper">
              <img src={LuzSavinon} alt="Luz Saviñon" className="clients-image" />
            </Col>
            <Col xs={6} sm={3} className="clients-wrapper">
              <img src={Rica} alt="Rica" className="clients-image" />
            </Col>
          </Row>
          <Row className="clients-brands-margin">
            <Col xs={6} sm={3} className="clients-wrapper">
              <img src={Genomma} alt="Genomma" className="clients-image" />
            </Col>
            <Col xs={6} sm={3} className="clients-wrapper">
              <img src={Ibero} alt="Ibero" className="clients-image" />
            </Col>
            <Col xs={6} sm={3} className="clients-wrapper">
              <img src={Gobierno} alt="Gobierno" className="clients-image" />
            </Col>
            <Col xs={6} sm={3} className="clients-wrapper">
              <img src={Sacmex} alt="Sacmex" className="clients-image" />
            </Col>
          </Row>
        </div>
      </section>
      <Conect />
    </Container>
  );
};

export default Home;
