// import Header from "./components/Header";
import Home from "./components/Pages/home";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AboutUs from "./components/Pages/aboutUs";
import DataManagement from "./components/Pages/dataManagement";
import DataScience from "./components/Pages/dataScience";
import DataVisualization from "./components/Pages/dataVisualization";
import DataLink from "./components/Pages/dataLink";
import ContactUs from "./components/Pages/contactUs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import SideBar from "./components/SideBar";

function App() {
  return (
    <div className="App">
      <SideBar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nosotros" element={<AboutUs />} />
          <Route path="/data-management" element={<DataManagement />} />
          <Route path="/data-science" element={<DataScience />} />
          <Route path="/data-visualization" element={<DataVisualization />} />
          <Route path="/data-link" element={<DataLink />} />
          <Route path="/contacto" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
