import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Conect from "../ConectForm.js";
import OurModel from "../OurModel";
import Services from "../Services";
import DataVisualizationLogo from "../assets/img/data_visualization_logo.svg";
import CustomDashboards from "../assets/img/GIF1_WEBDATALABMX-2022.gif";
import scrollUp from "../assets/img/Scroll_ColorStroke.svg";

import backgroundImage2 from "../assets/img/fondo.svg";
import backgroundImage3 from "../assets/img/fondo2.svg";

const DataVisualization = () => {
  return (
    <>
    <Container id="visualization">
      <section>
        <div className="title-section-divider dm-title-margin_bottom">
          <span className="title-section upperCase-font">
            <span className="secondary-color">Data</span>{" "}
            <span className="color-font dv-title-padding_rigth">
              Visualization
            </span>
            <img
              src={DataVisualizationLogo}
              alt="data management logo"
              className="dv-title_img"
            />
          </span>
        </div>
        <div className="scroll-image">
          <a href="#visualization">
            <img src={scrollUp} alt="scroll boton" />
          </a>
        </div>
        <Row>
          <Col sm={6}>
            <p className="dv-title ">Visualización</p>
            <p className="dv-title ">de datos</p>
            <p className="bold dv-title">para la toma</p>
            <p className="bold dv-title">DE DECISIONES</p>
          </Col>
          <Col sm={6} className="dv-space-margin_bottom">
            <p className="dv-subtitle">
              Trabajamos con las empresas para definir e implementar estrategias
              de Data Visualization y Reporting para transformar los datos en
              dashboards, reportes y presentaciones para un mejor entendimiento
              de los insights, facilitar la toma de decisiones y comunicarlas a
              los equipos de trabajo.
            </p>
          </Col>
        </Row>
        <section className="container-bar-orange">
        <div className="text-background-orange upperCase-font">
          <p>
              La visualización de datos permite comprender grandes cantidades de
              datos de un vistazo y de una mejor manera para la toma de
              decisiones.
          </p>
        </div>
      </section>
      <div className="bg-svg-1" style={{ backgroundImage: `url(${backgroundImage2})`, }}></div>
      <div className="bg-svg-2" style={{ backgroundImage: `url(${backgroundImage3})`, }}></div>

        <Row className="dv-space-columns">
          <Col sm={5}>
            <img
              src={DataVisualizationLogo}
              alt="custom dash"
              className="dv-custom_dash-img"
            />
            <p className="dv-custom_dash-title bold">
              Custom
              <br />
              Dashboards
            </p>
            <p className="dv-custom_dash-subtitle">
              Desarrollamos dashboards y reportes personalizados en base a la
              necesidades de nuestros clientes.
            </p>
          </Col>
          <Col sm={7}>
            <img className="imageVisual" src={CustomDashboards} alt="custom dash gif" width="100%" />
          </Col>
        </Row>
        <OurModel />
        <Services />
        <Conect />
      </section>
    </Container>
    </>
  );
};

export default DataVisualization;
