import React from "react";
import { Container } from "react-bootstrap";
import Conect from "../ConectForm.js";
import TitleHeader from "../TitleHeader/index.js";
import scrollUp from "../assets/img/Scroll_ColorStroke.svg";
import backgroundImage from "../assets/img/fondo-full.svg";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

import backgroundImage2 from "../assets/img/fondo.svg";
import backgroundImage3 from "../assets/img/fondo2.svg";

const AboutUs = () => {
  return (
    <>
      {/* <div className="about-bg" style={{ backgroundImage: `url(${backgroundImage})`, }}></div> */}

      <div className="bg-svg-1" style={{ backgroundImage: `url(${backgroundImage2})`, zIndex: "3", marginTop: "10vw" }}></div>
      <div className="bg-svg-2" style={{ backgroundImage: `url(${backgroundImage3})`, marginTop: "10vw" }}></div>
      <Container id="about" style={{zIndex: "10"}}>
        <TitleHeader />
        <section>
          <p className="title-background-orange upperCase-font font-l bold">
            Somos una organización que
          </p>
          <p className="description-section aboutUs-paddingTop">
            brinda soluciones tecnológicas en análisis de big data para micro,
            pequeñas, medianas y grandes empresas.
          </p>
          <div className="scroll-image">
            <a href="#about">
              <img src={scrollUp} alt="scroll boton" className="" />
            </a>
          </div>
          <p className="description-section aboutUs-paddingTop aboutUs-paddingBottom">
            <span className="bold">Transformamos datos en insights</span> que
            incrementen la eficiencia, maximicen la eficacia y generen valor.
          </p>

          <div className="titleHeader-background titleHeader-contac-block">
            <Link to="/contacto" className="titleHeader-link">
              Contacto <BsArrowRight />
            </Link>
          </div>
        </section>
        <Conect />
      </Container>
    </>
  );
};

export default AboutUs;
